{
  "name": "user-panel",
  "version": "1.1.30",
  "scripts": {
    "ng": "ng",
    "precommit-msg": "echo 'Pre-commit checks...' && exit 1",
    "start": "ng serve --port 4300",
    "start:prod": "ng serve --port 4300 --configuration production",
    "build": "ng build && yarn sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "format": "prettier --write .",
    "generate:schema": "graphql-codegen",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org thumbzone --project dashboard ./dist && sentry-cli sourcemaps upload --org thumbzone --project dashboard ./dist"
  },
  "pre-commit": [
    "format"
  ],
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.0",
    "@angular/cdk": "18.2.0",
    "@angular/common": "^18.2.0",
    "@angular/compiler": "^18.2.0",
    "@angular/core": "^18.2.0",
    "@angular/forms": "^18.2.0",
    "@angular/localize": "^18.2.0",
    "@angular/material": "18.2.0",
    "@angular/platform-browser": "^18.2.0",
    "@angular/platform-browser-dynamic": "^18.2.0",
    "@angular/router": "^18.2.0",
    "@apollo/client": "^3.11.4",
    "@graphql-codegen/cli": "^5.0.2",
    "@graphql-codegen/typescript": "^4.0.9",
    "@graphql-codegen/typescript-apollo-angular": "^4.0.0",
    "@graphql-codegen/typescript-operations": "^4.2.3",
    "@sentry/angular": "^8.28.0",
    "@sentry/cli": "^2.35.0",
    "apexcharts": "^3.52.0",
    "apollo-angular": "7.1.2",
    "crypto-js": "^4.2.0",
    "graphql": "^16",
    "jwt-decode": "^4.0.0",
    "keen-slider": "^6.8.6",
    "konva": "^9.3.14",
    "ng-otp-input": "^1.9.3",
    "ngx-ui-tour-md-menu": "^13.0.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.3",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.1",
    "@angular/cli": "^18.2.1",
    "@angular/compiler-cli": "^18.2.0",
    "@types/crypto-js": "^4.2.2",
    "@types/jasmine": "~5.1.4",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "pre-commit": "^1.2.2",
    "prettier": "^3.3.3",
    "typescript": "~5.5.4"
  }
}
