import { isDevMode } from '@angular/core';
import { version } from '../../../../package.json';
import { IEnvironment } from './env.interface';

const devEnvs: IEnvironment = {
  VERSION: version,
  apiUrl: 'https://api.thumbzone.ai',
  graphqlApiUrl: 'https://api.thumbzone.ai/graphql',
  s3Url: 'https://thumbzone-storage-nyc.nyc3.digitaloceanspaces.com',
  sentryDsn: 'https://775dd0e1931f3a2c5dba0faba0096a29@o4507715080749056.ingest.us.sentry.io/4507911272267776',
};

const prodEnvs: IEnvironment = {
  VERSION: version,
  apiUrl: 'https://api.thumbzone.ai',
  graphqlApiUrl: 'https://api.thumbzone.ai/graphql',
  s3Url: 'https://thumbzone-storage-nyc.nyc3.digitaloceanspaces.com',
  sentryDsn: 'https://775dd0e1931f3a2c5dba0faba0096a29@o4507715080749056.ingest.us.sentry.io/4507911272267776',
};

export const envs: IEnvironment = isDevMode() ? devEnvs : prodEnvs;
